<template>
  <div>
    <v-app-bar flat color="rgb(53, 80, 123)" class="h-10">
      <div class="titulo-pagina">
        <span style="color: white"> Dashboard </span>
      </div>
    </v-app-bar>
    <v-row>
      <v-card class="mx-auto my-12" max-width="374">
        <v-img height="250" src="./../../assets/img/kel-unid-sjp.jpg" />
        <v-card-title>K&L - São José dos Pinhais</v-card-title>
      </v-card>
      <v-card class="mx-auto my-12" max-width="374">
        <v-img height="250" src="./../../assets/img/kel-unid-joinville.jpeg" />
        <v-card-title>K&L - Joinville</v-card-title>
      </v-card>
      <v-card class="mx-auto my-12" max-width="374">
        <v-img
          height="250"
          src="./../../assets/img/kel-unid-sao-leopoldo.jpg"
        />
        <v-card-title>K&L - São Leopoldo</v-card-title>
      </v-card>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'SystemBodyPage',
  metaInfo() {
    return {
      title: 'Dashboard',
      titleTemplate: (chunk) => `${chunk} | ${process.env.VUE_APP_NAME}`,
      htmlAttrs: {
        lang: 'pt-BR',
      },
    };
  },
};
</script>
